<template>
  <footer>
    <div
      class="bottom-footer-bar d-flex flex-column flex-md-row
        justify-content-center align-items-center"
    >
      <h5>HEINDIESEIN &copy; - 2023</h5>
      <h5 class="d-none d-md-inline">|</h5>
      <h5>Website door: <a href="https://mwsd.dev" target="_blank">Marc Wiggerman</a></h5>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped lang="scss">
.bottom-footer-bar {
  background-color: black;
  color: white;

  & h5 {
    padding: 1em;
    margin: 0;
    font-weight: bold;
    font-size: .8em;

    & a {
      color: white;
      text-decoration: none;
      opacity: 0.8;
    }
  }
}
</style>
