import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { createApp } from 'vue';

import App from './App.vue';
import router from './router';

import './firebase';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

const VueScrollTo = require('vue-scrollto');

createApp(App)
  .use(router)
  .use(BootstrapVue)
  .use(IconsPlugin)
  .use(VueScrollTo)
  .mount('#app');
