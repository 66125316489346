<template>
  <div class="d-flex flex-row justify-content-start align-items-center">
      <b-icon-envelope-fill v-if="iconName == 'envelope-fill'"></b-icon-envelope-fill>
      <b-icon-instagram v-if="iconName == 'instagram'"></b-icon-instagram>
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'InformationRow',
  props: {
    iconName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
span {
  font-weight: 500;
  margin: 0 1em;
}

svg {
  fill: $dark-red;
  font-size: 1.2em;
}

a {
  font-size: 1.2em;
  font-family: 'Teko', sans-serif;
  font-weight: 400;
  margin: 0 1em;

  color: black;
  text-decoration: none;
  text-align: left;
  &:visited {
    color: black;
    text-decoration: none;
  }
}

</style>
