<template>
  <div class="home">
    <home-hero id="home-hero"/>
    <meesterstuk></meesterstuk>
    <work-presentor id="all-works"/>
    <b-container fluid class="art-block p-5">
      <b-row>
        <b-col cols="12" md="6">
          <bio-section id="bio-section"/>
        </b-col>

        <b-col cols="12" md="6" class="pt-5 pt-md-0">
          <contact-section id="contact-section"/>
        </b-col>
      </b-row>
    </b-container>
    <app-footer style="width: 100vw;"/>
  </div>
</template>

<script>
import HomeHero from '@/components/Hero/HomeHero.vue';
import WorkPresentor from '@/components/Work/WorkPresentor.vue';
import Meesterstuk from '@/components/Content/Meesterstuk.vue';

import BioSection from '@/components/Bio/BioSection.vue';
import ContactSection from '@/components/Contact/ContactSection.vue';
import AppFooter from '@/components/Footer/Footer.vue';

export default {
  name: 'HomeComponent',
  components: {
    HomeHero,
    WorkPresentor,
    Meesterstuk,
    BioSection,
    ContactSection,
    AppFooter,
  },
};
</script>
