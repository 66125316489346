<template>
  <div class="bio-container">
    <h1>BIO</h1>

    <b-container fluid class="p-0">
      <b-row>
        <b-col cols="12" sm="6" lg="8">
          <span>
            Ik ben iemand die zoveel dingen leuk vindt dat het onmogelijk wordt om een keuze]
            te maken,
            iemand die zichzelf letterlijk moet dwingen om zich maar op één ding te focussen.
            Iemand die na twee jaar ArtEZ te hebben gevolgd belandde in een diepe depressie en
            hierna besloot om Edelsmid te worden.
            Iemand die langzaamaan steeds beter de vraag "Wie ben ik?" weet te beantwoorden.
          </span>
        </b-col>

        <b-col cols="12" sm="6" lg="4">
          <img src="../../assets/hein_profile.png" alt="">
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'BioSection',
};
</script>

<style scoped lang="scss">
.bio-container {
  padding: 2em;
  border: 4px solid black;

  & h1 {
    color: $dark-red;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
