import { initializeApp } from 'firebase/app';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyCBghldCugFeywQqfeWilX-HfxiRcpJlkk',
  authDomain: 'heindiesein-1fcf5.firebaseapp.com',
  projectId: 'heindiesein-1fcf5',
  storageBucket: 'heindiesein-1fcf5.appspot.com',
  messagingSenderId: '416572172533',
  appId: '1:416572172533:web:192d1406e1b1786255bdbc',
};

initializeApp(firebaseConfig);

// if (process.env.JEST_WORKER_ID !== undefined) {
//   console.log('Running a Jest Test mode');
//   db.useEmulator('localhost', 9997);
// }
