<template>
  <b-container class="p-5" fluid>
    <b-row no-gutters>
      <b-col>
        <p class="text-center click-info">Klik op de afbeeldingen om meer te lezen</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
        v-for="art in artData"
        :key="art.id"
        class="art-block"
        @click="openInfo(art.id)"
      >
        <b-container fluid class="art-block p-0" v-if="art.url.match(/^Hanger_\d+$/g)">
          <b-row>
            <b-col :cols="currentSelectedArtObjectIdImage === art.id ? 6 : 12" >
              <img :src="require(`../../assets/header_images/${art.url}.jpg`)"
              :ref="`${art.id}-art-image`" class="no-drag art-image" alt="art"/>
            </b-col>

            <transition name="fade">
              <b-col cols="6" v-if="currentSelectedArtObjectIdText === art.id" class="art-info">
                <h1 class="text-uppercase mb-0">{{art.name}}</h1>
                <hr class="mt-0">
                <p>{{art.description}}</p>
              </b-col>
            </transition>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import testData from './testdata.json';

export default {
  name: 'WorkPresentor',
  data() {
    return {
      artData: testData,
      currentSelectedArtObjectIdImage: null,
      currentSelectedArtObjectIdText: null,
      animationSpeed: 400,
    };
  },
  components: {
  },
  methods: {
    openInfo(id) {
      // If Null
      if (this.currentSelectedArtObjectIdImage === null
        && this.currentSelectedArtObjectIdText === null) {
        this.currentSelectedArtObjectIdImage = id;
        setTimeout(() => { this.currentSelectedArtObjectIdText = id; }, this.animationSpeed);
      }

      // If different
      if (this.currentSelectedArtObjectIdImage !== id
        && this.currentSelectedArtObjectIdText !== id) {
        this.currentSelectedArtObjectIdText = null;
        setTimeout(() => { this.currentSelectedArtObjectIdImage = null; }, this.animationSpeed);
        setTimeout(() => { this.openInfo(id); }, 2 * this.animationSpeed);
      }

      // If Same
      if (this.currentSelectedArtObjectIdImage === id
        && this.currentSelectedArtObjectIdText === id) {
        this.currentSelectedArtObjectIdText = null;
        setTimeout(() => { this.currentSelectedArtObjectIdImage = null; }, this.animationSpeed);
      }
    },
  },
  mounted() {
    this.openInfo(this.artData[0].id);
  },
};
</script>

<style scoped lang="scss">
$height: 30em;

.art-block {
  height: $height;
  margin-bottom: 1.5em;
  cursor: pointer;
}

.art-info {
  height: $height;
  overflow-y: scroll;
}

.art-image {
  transition: width 1s ease-in-out;
  width: 100%;
  height: $height;
  object-fit: cover;
}

.col-12 {
  transition: all .4s ease;
}

.col-6 {
  transition: all .4s ease;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

h1 {
  color: $dark-red;
}

hr {
  border: 1px solid $dark-red;
}

.click-info {
  font-size: .8em;
  font-weight: bold;
}
</style>
