<template>
  <div class="bio-container">
    <h1>CONTACT</h1>
    <p>
      Like my work? Here is how to get in contact:
    </p>

    <information-row iconName="envelope-fill" class="my-2">
      <a href="mailto:heindiesein@gmail.com" target="_blank">heindiesein@gmail.com</a>
    </information-row>

    <information-row iconName="instagram" class="my-2">
      <a href="https://instagram.com/heindiesein?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noreferrer noopener">Follow me on Instagram!</a>
    </information-row>
  </div>
</template>

<script>
import InformationRow from './InformationRow.vue';

export default {
  name: 'ContactSection',
  components: {
    InformationRow,
  },
};
</script>

<style scoped lang="scss">
.bio-container {
  padding: 2em;
  border: 4px solid black;

  & h1 {
    color: $dark-red;
  }
}
</style>
