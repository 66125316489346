<template>
  <div class="p-5">
    <div class="w-100 d-flex flex-row justify-content-start align-items-end pb-2">
      <h1 class="my-0">MEESTERSTUK</h1>
      <hr class="w-100 ms-2"/>
    </div>
    <p>Het meesterstuk is de laatste opdracht van de opleiding Academie voor Edelsmeden te Zeist.
      De studenten ontwerpen en vervaardigen dit stuk grotendeels buiten de opleidinglocatie. Door
      het voltooien van het meesterstuk laten ze zien dat ze over voldoende creatieve en technische
      vaardigheden beschikken om zichzelf meesteredelsmid te mogen noemen.</p>

    <p>Mijn meesterstuk zou iets moeten vertellen. Het zou diepe menselijke emoties moeten
      uitbeelden. Ik wilde iets creëren dat leegte vorm geeft, het gevoel dat je krijgt wanneer
      je naar een dor en verlaten landschap kijkt. Het zou je ongemakkelijk moeten laten voelen,
      een onderbuik gevoel dat er iets mis is.</p>

    <p>Met deze gedachten begon ik aan dit project, maar deze gedachten vervaagden zodra ik het
      eerste experiment uitvoerde. Het verhaal verdween, de symboliek verdween, en ook de diepe
      emoties en gevoelens verdwenen. Het enige wat overbleef was de vraag: 'Vind ik dit wel mooi?'
      en 'Oh, dit zou ook gaaf kunnen zijn om te proberen.'</p>

    <p>En zo heb ik, na een uitgebreid proces van experimenteren en mezelf dwingen tot het maken
      van keuzes dit stuk gecreëerd. Mijn meesterstuk bestaat uit een spang met 4 hangers die
      samen een reeks vormen.</p>
  </div>
</template>

<script>
export default {
  name: 'MeesterstukComponent',
};
</script>

<style scoped lang="scss">

h1 {
  font-family: 'Teko', sans-serif;
  color: $dark-red;
  font-weight: 800;
}

hr {
  border: 1px solid $dark-red;
}

</style>
