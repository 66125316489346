<template>
  <div class="header-container">
    <navigation-bar class="navbar"/>
  </div>
</template>

<script>
import NavigationBar from './NavigationBar.vue';

export default {
  name: 'HomeHero',
  data() {
    return {
      headerImages: [
        'https://placeimg.com/1024/480/arch',
        'https://placeimg.com/1024/480/animals',
        'https://placeimg.com/1024/480/nature',
        'https://placeimg.com/1024/480/tech',
      ],
      currentHeaderImageIndex: 0,
    };
  },
  components: {
    NavigationBar,
  },
  mounted() {
    setInterval(() => {
      if (this.currentHeaderImageIndex === this.headerImages.length - 1) {
        this.currentHeaderImageIndex = 0;
      } else {
        this.currentHeaderImageIndex += 1;
      }
    }, 5000);
  },
};
</script>

<style scoped lang="scss">
.navbar {
  height: 50%;
}

.header-container {
  height: 90vh;
  background-image: url('../../assets/header_images/header_image-2.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

</style>
