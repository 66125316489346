<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="white-blur-background">
      <div class="d-flex flex-column align-items-center pt-2">
        <img src="../../assets/hein_logo.png" alt="" class="logo pb-3"/>
        <span id="name">HEIN HEIJNEKAMP</span>
        <span id="subtitle">Edelsmid</span>
      </div>

      <div class="d-flex flex-row justify-content-center link-container">
        <a href="#" v-scroll-to="'#all-works'">Work</a>
        <a href="#" v-scroll-to="'#bio-section'">Bio</a>
        <a href="#" v-scroll-to="'#contact-section'">Contact</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
};
</script>

<style scoped lang="scss">
span {
  font-family: 'Teko', sans-serif;
  margin: 0;

  transition: color .1s ease-in-out;

  &:hover {
    color: $dark-red;
  }

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  cursor: pointer;
}

#name {
  font-weight: 600;
  font-size: 4em;
  @include sm-max {
    font-size: 3em;
  }
}

#subtitle {
  font-size: 2.8em;
  transform: translateY(-30px);
  @include sm-max {
    font-size: 2em;
    transform: translateY(-20px);
  }

  font-weight: 300;
}

a {
  margin: 1em;
}

.link-container {
  margin-top: -1em;
}

.logo {
  width: 200px;

  transition: transform 250ms;
  &:hover {
    transform: rotate(15deg);
  }
}

.white-blur-background {
  background-color: rgba(white, 0.2);
  backdrop-filter: blur(10px);
}
</style>
